import React from "react";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/index.css";

function Title() {
  return (
    <div className="row" id="title-row">
      <a className="col Title" href="https://duyyudus.me">
        Yudus Valley
      </a>
    </div>
  );
}

function Greet() {
  return (
    <div className="row" id="greet-row">
      <div className="col Greet">
        welcome to{" "}
        <span className="color-secondary-cool-3">
          <img
            src="yudus-valley-small.png"
            className="img-rounded Greet-logo"
            alt="Yudus Valley"
          />
        </span>
      </div>
    </div>
  );
}

function Intro() {
  return (
    <div className="row" id="intro-row">
      <div className="col Intro">
        <p>
          hello everyone
          <br />
          <br />
          this is <b className="color-secondary-hot-3">duyyudus</b>, shorthand{" "}
          <b className="color-secondary-hot-3">dp</b>
          <br />
        </p>

        <p>i used to be</p>

        <div className="mx-4">
          <p>
            • game programmer
            <br />
            • graphic designer
            <br />
            • 3D artist
            <br />
          </p>
        </div>

        <p>now i am</p>

        <div className="mx-4">
          <p>
            • full-stack developer
            <br />
            • blockchain engineer
            <br />
            • expert on 3D computer graphic and game development
            <br />• the founder of{" "}
            <b className="color-secondary-cool-3">Yudus Labs</b>, a dev lab
            focused on experimental inter-chain gaming Dapps and services
            <br />
            <br />
          </p>
        </div>

        <p>
          <b className="color-secondary-cool-3">Yudus Valley</b> is the home of
          many entities, including
          <div className="mx-4">
            <p>
              • <b className="color-secondary-cool-3">Yudus Labs</b>, more
              details here{" "}
              <a href="https://yudus.dev" className="custom-link">
                https://yudus.dev
              </a>
              <br />• <b className="color-secondary-cool-3">Yudus Media</b>, our
              official media outlet and graphic production house, coming soon...
            </p>
          </div>
          that's it
          <br />
          <br />
          if you need to get in touch, just drop me a message via{" "}
          <div className="mx-4">
            <p>
              • <i>telegram</i>{" "}
              <a
                className="custom-link"
                href="https://t.me/duyyudus"
                alt="Telegram"
              >
                https://t.me/duyyudus
              </a>
              <br />• <i>twitter</i>{" "}
              <a
                className="custom-link"
                href="https://twitter.com/duyyudus"
                alt="Twitter"
              >
                https://twitter.com/duyyudus
              </a>
            </p>
          </div>
          have a nice day :)
        </p>
      </div>
    </div>
  );
}

export default function Home() {
  return (
    <div className="container Home">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Yudus Valley</title>
        <link rel="canonical" href="https://duyyudus.me" />
      </Helmet>
      <Title />
      <Greet />
      <Intro />
    </div>
  );
}
